<template>
  <v-card width="450">
    <v-toolbar
      dense
      flat
    >
      <v-icon
        left
        :color="fileTypes[fileExt].color"
      >
        {{ fileTypes[fileExt].icon }}
      </v-icon>
      <span class="font-weight-medium">{{ fileName }}</span>
      <v-spacer />
      <v-btn
        icon
        color="pohjala_blue"
        @click="closeDialog"
      >
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="py-3">
      <v-text-field
        ref="textToCopy"
        label="Jagatav viide dokumendile"
        dense
        readonly
        outlined
        color="pohjala_blue"
        :value="shareUrl"
        :append-icon="mdiContentCopy"
        hide-details
        @click:append="copyText"
      />
    </v-card-text>

    <v-card-actions>
      <v-btn
        text
        color="pohjala_blue"
        @click="onPreviewDocument"
      >
        <v-icon left>
          {{ mdiOpenInNew }}
        </v-icon>
        Ava veebilehitsejas
      </v-btn>
      <v-btn
        text
        color="pohjala_blue"
        @click="onDownloadDocument"
      >
        <v-icon left>
          {{ mdiDownload }}
        </v-icon>
        Lae alla
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, ref } from '@vue/composition-api';
import {
  mdiClose, mdiContentCopy, mdiOpenInNew, mdiDownload,
} from '@mdi/js';
import { saveAs } from 'file-saver';
import fileTypes from '../../../composables/use-file-type-definitions';
import dialogModule from '../../../store/modules/dialog';

const vectorIcons = {
  mdiClose, mdiContentCopy, mdiOpenInNew, mdiDownload,
};
export default {
  setup() {
    const {
      dialogPayload, openOverlay, setOverlayOptions, closeDialog,
    } = dialogModule();
    const textToCopy = ref(null);
    // eslint-disable-next-line no-bitwise
    const getFileExtPart = (fname) => fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
    const fileExt = computed(() => getFileExtPart(dialogPayload.value.file));
    const fileName = computed(() => {
      if (!dialogPayload.value) return '';
      const parts = dialogPayload.value.file.split('/');
      return parts[parts.length - 1];
    });
    const onDownloadDocument = () => {
      saveAs(dialogPayload.value.url, fileName.value);
    };
    const onPreviewDocument = () => {
      const allowedImageExtensions = ['png', 'jpeg', 'bmp', 'gif', 'jpg'];
      const allowedMediaExtensions = ['aac', 'avi', 'mid', 'midi', 'mp3', 'mpeg'];
      if (
        allowedImageExtensions.includes(fileExt.value)
                || allowedMediaExtensions.includes(fileExt.value)
      ) {
        setOverlayOptions({
          component: 'DocumentPreview',
          payload: {
            file: dialogPayload.value.file,
            fileExt: fileExt.value,
            url: dialogPayload.value.url,
          },
        });
        openOverlay();
      } else {
        const url = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(dialogPayload.value.url)}`;
        const newWin = window.open(url, '_blank');
        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
          console.log('popup blocked');
          alert('Pop-up blokeeriti');
          // POPUP BLOCKED
          // DOWNLOAD HERE
          onDownloadDocument();
        } else {
          console.log('pop not blocked');
        }
      }
    };
    const shareUrl = computed(() => {
      if (!dialogPayload.value) return '';
      const parts = dialogPayload.value.file.split('/');
      return `https://siseveeb.pohjala.ee/failid/${parts.slice(1).join('/')}`;
    });
    const copyText = () => {
      const copyEl = textToCopy.value.$el.querySelector('input');
      copyEl.select();
      document.execCommand('copy');
    };

    return {
      ...vectorIcons,
      dialogPayload,
      fileName,
      fileTypes,
      fileExt,
      onPreviewDocument,
      shareUrl,
      textToCopy,
      copyText,
      onDownloadDocument,
      closeDialog,
    };
  },
};
</script>
